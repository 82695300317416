@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/Ubuntu-Regular.ttf');
}

@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 400;
  src: url('../../assets/fonts/Ubuntu-Italic.ttf');
}

@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/Ubuntu-Bold.ttf');
}

@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 700;
  src: url('../../assets/fonts/Ubuntu-BoldItalic.ttf');
}

@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 300;
  src: url('../../assets/fonts/Ubuntu-Light.ttf');
}

@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 300;
  src: url('../../assets/fonts/Ubuntu-LightItalic.ttf');
}

@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  src: url('../../assets/fonts/Ubuntu-Medium.ttf');
}

@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 500;
  src: url('../../assets/fonts/Ubuntu-MediumItalic.ttf');
}