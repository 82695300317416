.essential-theme {
  .is-collapsible-list-with-divider {
    ion-item-divider {
      background-color: white;

      h2 {
        font-size: 0.8rem;

        ion-icon {
          margin-left: 6px;
        }
      }

        span {
          font-size: 0.9rem;
          font-weight: 400;
          color: var(--ion-color-is-text-dark);
        }

        span:first-child {
          font-weight: 300;
          color: var(--ion-color-is-mute-normal);
        }


      ion-col:not(:first-child) {
        margin-right: 8px;
      }
    }

    ion-item-divider.is-items-show {
      background-color: var(--is-item-divider-background-active);
    }

    ion-item-divider ~ ion-item {
      display: none;
    }

    ion-item-divider.is-items-show ~ ion-item {
      display: block;
    }

    ion-item:not(:first-child) {
      align-items: flex-start;

      h2 {
        font-weight: 400;
      }

      p {
        font-size: 0.8rem;
        font-weight: 400;
        color: var(--ion-color-is-mute-normal);
      }

      ion-note {
        font-size: 1.0rem;
        font-weight: 300;
        margin-top: 11px;
        color: var(--ion-color-is-mute-dark);
      }
    }
  }
}
