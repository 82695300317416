.essential-theme {
  .is-list-condensed {
    ion-item {
      ion-avatar img {
        min-height: 44px;
        min-width: 44px;
      }

      .is-list-vertical-buttons ion-checkbox {
        --is-checkbox-overlay-left: 85%;
        --is-checkbox-overlay-height: 50%;
        --border-color: var(--ion-color-is-mute-normal);
      }

      ion-label h2 {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      h2 {
        margin-bottom: unset;
        font-size: 1rem;
        font-weight: 300;
        color: var(--ion-color-is-text-dark);
      }

      p {
        margin-bottom: unset;
        line-height: unset;
        font-size: 0.8rem;
        font-weight: 300;

        span {
          color: var(--ion-color-is-mute-dark);
        }
      }

      ion-icon {
        font-size: 1.4em;
      }

      .is-condensed-note {
        margin: 13px 8px 13px 0;
        display: flex;
        align-self: flex-start;

        p {
          color: var(--ion-color-is-mute-normal);
        }
      }
    }

    ion-item-options {
      border-bottom: 1px solid var(--ion-color-is-mute-light);

      ion-item-option {
        --is-button-native-font-weight: 400;
      }
    }
  }
}
